<template>
  <div>
    <el-container>
      <CommonTree
        ref="CommonTree"
        style="border-right: none"
        treeTitle="组织架构"
        :popoverShow="false"
        :treeData.sync="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :currentNodeKey="deptId"
        :defaultCheckedKeys="defaultCheckedKeys"
        @getNodeClick="treeNodeClick"
        :isShowdig="false"
        :showCheckbox="false"
        :treeExpand="false"
        @include-down="includeDown"
      />
      <el-main>
        <head-layout
          head-title="培训记录"
        ></head-layout>
        <grid-head-layout
          ref="gridHeadLayout"
          :grid-head-btn="gridHeadBtn"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="crudLoading"
          :data-total="page.total"
          :page="page"
          @grid-row-detail-click="rowDetail"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
        >
          <template #customBtn="{row}">
            <el-button
              type="text"
              style="margin-right: 5px"
              @click="rowEdit(row)"
            >编辑
            </el-button>
            <el-button
              type="text"
              v-if="row.isFinish == 0"
              @click="rowContinuing(row)"
            > 继续培训
            </el-button>
            <el-button
              type="text"
              @click="rowDetail(row)"
              v-if="row.isFinish ==1"
            >详情
            </el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
  </div>
</template>


<script>
import CommonTree from "@/views/components/com_tree";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {deptChildIds, deptChildTree, treeReport} from "@/api/reportTemplate";
import {getDetailBySiteTrainingId, getList} from "@/api/training/recording";
import {mapGetters} from "vuex";
import {getSafetyTrainingTree} from "@/api/knowledgeClassifcation/knowledge";

export default {
  data() {
    return {
      form: {},
      query: {},
      treeReadOnlyFlag: false,
      tableData: [],
      trainingTypeData: [],
      treeLoading: false,
      crudLoading: false,
      dicData: [],
      node: {}, //左侧树node
      fullscreen: false,
      showTreeDialog: false,
      classifyId: '',
      showDialog: false,
      formType: '',
      formList: {},
      treeData: [],
      selectionList: [],
      defaultProps: {
        label: "fullName",
        children: 'children'
      },
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchTitle: 'fullName',
      deptId: '',
      defaultCheckedKeys: [],
    }
  },
  computed: {
    ...mapGetters(["permission", 'colorName', 'userInfo']),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          label: "",
          prop: "trainingName",
          span: 4,
          type: "input",
          placeholder: "请输入培训名称",
        },
        {
          label: "",
          prop: "time",
          span: 4,
          type: "date",
          placeholder: "请选择培训日期",
          component: {
            name: 'av-date-picker',
            props: {
              type: 'date',
              format: 'yyyy-MM-dd'
            },
          },
        },
        {
          label: "",
          prop: "trainingType",
          type: "tree",
          dicData: this.trainingTypeData,
          props: {
            label: "title",
            value: "title",
            key: "key",
          },
          span: 4,
          placeholder: "请选择培训类型",
        },
      ];
    },
    gridHeadBtn() {
      return [];
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: "序号",
        menuWidth: 180,
        selection: true,
        linklabel: "trainingName",
        column: [
          {
            label: "培训名称",
            prop: "trainingName",
            align: "center",
            overHidden: true,
          },
          {
            label: "所属项目",
            prop: "project",
            align: "center",
            overHidden: true,
          },
          {
            label: "所属场景",
            prop: "trainingScenarios",
            align: "center",
            overHidden: true,
          },
          {
            label: "发起人",
            prop: "sponsor",
            align: "center",
            width: 120,
            overHidden: true,
          },
          {
            label: "所属公司",
            prop: "firm",
            align: "center",
            overHidden: true,
          },
          {
            label: "培训时间",
            prop: "createTime",
            align: "center",
            width: 180,
            overHidden: true,
          },
          {
            label: "是否完成",
            prop: "isFinish",
            align: "center",
            dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: "number",
            width: 110,
            overHidden: true,
          },
        ],
      };
    },
  },
  components: {
    GridLayout,
    HeadLayout,
    CommonTree
  },
  mounted() {
    this.getDeptChildTreeData();
    this.onLoad(this.page);
    this.initTree();
  },
  methods: {
    initTree() {
      getSafetyTrainingTree()
        .then((result) => {
          this.trainingTypeData = result.data.data;
          this.node = this.treeData[0];
          this.treeLoading = false;
        })
        .catch((err) => {
          this.treeLoading = true;
        });
    },
    rowEdit(row) {
      this.$router.push({
        path: "/business/safetyenment/train/recording/edit",
        query: {
          id: row.id
        },
      });
    },
    rowDetail(row) {
      this.$router.push({
        path: "/business/safetyenment/train/recording/form",
        query: {
          id: row.id
        },
      });
    },
    rowContinuing(row) {
      getDetailBySiteTrainingId(row.siteTrainingId).then((res) => {
        if (res.data.code == 200) {
          if (res.data.data) {
            this.$router.push({
              path: '/business/training/onSiteTraining/edit',
              query: {
                row: JSON.stringify(res.data.data),
                type: 'edit',
              }
            });
          }
        }
      })
    },
    gridHeadSearch() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    gridHeadEmpty() {
      this.query = {};
      this.getDeptChildTreeData()
      this.onLoad(this.page);
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params = {}) {
      if (this.$refs.gridHeadLayout.searchForm.time) {
        let selectedDate = new Date(this.$refs.gridHeadLayout.searchForm.time);
        // 将日期转换为 "yyyy-MM-dd" 格式的字符串
        this.$refs.gridHeadLayout.searchForm.time = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`
      }
      this.page = page;
      this.crudLoading = true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm, this.query)
      )
        .then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.page.total = data.total;
          this.tableData = data.records;
          this.crudLoading = false;
          this.selectionClear();
        })
    },
    // 获取组织架构和顶部导航
    getDeptChildTreeData() {
      deptChildTree().then((res) => {
        this.treeData = []
        if (res.data.code == 200) {
          this.treeData.push(res.data.data);
          this.deptId = this.treeData[0].id;
          this.$nextTick(() => {
            this.defaultCheckedKeys.push(this.deptId)
          })
          this.getTopMenu()
        }
      })
    },
    getTopMenu() {
      treeReport(
        this.deptId
        // includeStatus:this.includeStatus
      ).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          this.replaceData(data);
          this.topSidderData = data;
        }
      })
    },
    replaceData(data) {
      data.forEach((item) => {
        if (item.reportVOList && item.reportVOList.length != 0 && item.children) {
          item.children = item.children.concat(item.reportVOList);
        } else if (item.reportVOList && item.reportVOList.length != 0 && item.children == undefined) {
          item.children = new Array();
          item.children = item.children.concat(item.reportVOList);
        } else {
          item.children = new Array();
        }
        if (item.reportName) {
          item.reportCategoryName = item.reportName;
        }
        if (item.reportReviewPath == undefined) {
          item.reportReviewPath = '';
        }
        if (item.children && item.children.length != 0) {
          this.replaceData(item.children);
        }
      })
    },
    treeNodeClick(item) {
      let params = {
        firm: item.id
      }
      this.onLoad(this.page, params)
      this.deptId = item.id;
      if (this.includeStatus) {
        this.getDeptIds(item.id);
      }
      this.getDeptUrl(this.deptId);
    },
    getDeptUrl(deptId) {
      if (this.urlPath != '') {
        if (this.includeStatus) {
          deptChildIds(deptId).then((res) => {
            if (res.data.code == 200) {
              this.deptIds = res.data.data;
              this.iframeUrl = this.urlPath + '&type=0' + '&deptId=' + this.deptIds;
            }
          })
        } else {
          this.iframeUrl = this.urlPath + '&type=0' + '&deptId=' + deptId;
        }
      }
    },
    includeDown(e) {
      this.includeStatus = e;
      if (this.includeStatus) {
        this.$loading()
        deptChildIds(this.deptId).then((res) => {
          if (res.data.code == 200) {
            this.deptIds = res.data.data;
            this.iframeUrl = this.urlPath + '&type=0' + '&deptId=' + this.deptIds;
            this.$loading().close()
          }
        })
      } else {
        this.iframeUrl = this.urlPath + '&type=0' + '&deptId=' + this.deptId;
      }
    },
  }
}

</script>
<style scoped lang="scss">
</style>
